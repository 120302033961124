import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Button } from 'reactstrap';
import { useMain } from 'src/views/hooks/useMain';
import { Box } from '@mui/system';
import TextInputs from 'src/components/inputs/TextInputs';

const SalesMaskingInfo = ({ data, refreshData }) => {
  const { updateOwnerSalesMask, setShowMessage } = useMain();

  const [isSalesMasked, setIsSalesMasked] = useState(data?.isSalesMasked || false); // Default to current status
  const [password, setPassword] = useState('');
  const [openDialogue, setOpenDialogue] = useState(false);
  const [pendingMaskStatus, setPendingMaskStatus] = useState(null); // Tracks pending toggle state
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleToggleChange = (isMasked) => {
    setPendingMaskStatus(!isMasked); // Set the opposite of the current state
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setPendingMaskStatus(null); // Reset pending state
  };

  const handleMaskToggle = async () => {
    if (password === '') {
      setShowMessage({ message: 'Please enter login password', messageType: 'error' });
      return;
    }
    setIsSubmitting(true); // Set submitting state to true
    try {
      const res = await updateOwnerSalesMask(data._id, {
        isSalesMasked: pendingMaskStatus,
        password,
      });

      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully updated sales masking',
          messageType: 'success',
        });
        setIsSalesMasked(pendingMaskStatus);
        setPassword('');
        setOpenDialogue(false);
        refreshData(data._id);
      } else {
        setShowMessage({ message: res.message || 'Failed to update settings', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    }finally{
        setIsSubmitting(false); // Reset the submitting state
    }
  };

  const maskData = (value) => {
    const valueStr = value.toString(); // Convert the number to a string
    if (isSalesMasked) {
      const maskLength = Math.ceil(valueStr.length);
      return '*'.repeat(maskLength) + valueStr.substring(maskLength); // Now it works as expected
    } else {
      return valueStr;
    }
  };
  

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: '#FAFAFA',
        borderRadius: '10px',
        border: '0.5px solid #CFCFCF',
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={isSalesMasked}
            onChange={() => handleToggleChange(isSalesMasked)}
          />
        }
        label="Mask Sales Information"
      />

      <Typography variant="h6" style={{ marginTop: '20px' }}>
        {isSalesMasked ? 'Masked Sales Information' : 'Original Information'}
      </Typography>
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        {maskData(20300)}
      </Typography>

      <Dialog open={openDialogue} onClose={handleCloseDialogue}>
        <DialogTitle>Confirm Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to turn masking {pendingMaskStatus ? 'ON' : 'OFF'}?
          </DialogContentText>
          <Box style={{ marginTop: '10px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInputs
                  title="Login Password"
                  name="password"
                  value={password}
                  handleChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogue}>Cancel</Button>
          <Button onClick={handleMaskToggle} autoFocus disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalesMaskingInfo;
