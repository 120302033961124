import React, { useRef } from 'react';
import './BillPdf.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMain } from 'src/views/hooks/useMain';
import { useNavigate, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Avatar, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import numWords from 'num-words';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { Link } from 'react-router-dom';
import { MessageData } from './MessageData';

function BillPdf() {
  const [data, setData] = useState({});
  const [clientData, setClientData] = useState({});
  const [loading, setLoading] = useState(true);
  const [feedbackUrl, setFeedbackUrl] = useState('');
  const [billUrl, setBillUrl] = useState('');
  const componentRef = useRef();
  const { billId } = useParams();
  const [updateBranchName, setUpdateBranchName] = useState(false)
  const [baseTotal, setBaseTotal] = useState(0)

  const {
    fetchBillById,
    setShowMessage,
    branchData,
    activeBranchId,
    fetchClientByNumber,
    getFeedbackUrl,
    getBillUrl,
  } = useMain();

  // useEffect(() => {
  //   setUpdateBranchName(!updateBranchName)
  // }, [branchData?.branchName, branchData?.gstNumber])
  // console.log(branchData);

  const printBillHandler = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${billId}`,
    // onBeforeGetContent:()=>
    // onBeforePrint:()=>
    onAfterPrint: () =>
      setShowMessage({ message: 'Bill downloaded successfully', messageType: 'success' }),
    onPrintError: () =>
      setShowMessage({ message: 'Something error occures to print bill', messageType: 'error' }),
  });

  const getData = async (billId) => {
    try {
      const res = await fetchBillById(billId, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setData(res.data || {});
      } else {
        // setShowMessage({ message: res.message || 'bill not fetched', messageType: 'error' });
      }
    } catch (error) {
      // setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const getUrl = async () => {
    try {
      const res = await getFeedbackUrl(billId, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setFeedbackUrl(res.data || '');
      } else {
        // setShowMessage({ message: res.message || 'bill not fetched', messageType: 'error' });
      }
    } catch (error) { }
  };

  const getBillPage = async () => {
    try {
      const res = await getBillUrl(billId);
      if (res.statusCode === 200) {
        setBillUrl(res.data || '');
      } else {
        // setShowMessage({ message: res.message || 'bill not fetched', messageType: 'error' });
      }
    } catch (error) { }
  };

  const whatsAppBillHandler = () => {
    let url = MessageData(data, clientData, branchData, feedbackUrl, billUrl);
    console.log(url);
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (billId && activeBranchId) {
      getData(billId);
      getUrl();
      getBillPage();
    }
    return () => {
      setData({});
      setClientData({});
      setFeedbackUrl('');
      setBillUrl('');
    };
  }, [billId, activeBranchId]);

  const getClientData = async (clientNumber) => {
    try {
      const res = await fetchClientByNumber(clientNumber, activeBranchId);
      if (res.statusCode === 200) {
        setClientData(res.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (data?.clientNumber && activeBranchId) {
      getClientData(data.clientNumber);
    }
    return () => {
      setClientData({});
    };
  }, [data?.clientNumber, activeBranchId]);

  const allTotal = () => {
    const totalProducts = data?.products?.reduce((total, product) => {
      return total + (product.price * product.quantity);
    }, 0);
    const totalServices = data?.services?.reduce((total, service) => {
      return total + (service.price * service.quantity);
    }, 0);
    setBaseTotal(totalProducts + totalServices)
  }

  useEffect(() => {
    allTotal()
  }, [data])

  console.log(data, "PDFdattta")


  return (
    <PageContainer title="Bill Invoice">
      <DashboardCard2 title="Bill Invoice">
        {loading ? (
          <ShowLoader />
        ) : Object.keys(data).length === 0 ? (
          <div>No bill record found</div>
        ) : (
          <div>
            <div ref={componentRef} style={{ padding: '20px' }}>
              <div className="print-body">
                <div className="print-header">
                  <div>
                    {data?.branchDetails?.logo && (
                      <Avatar
                        src={data?.branchDetails?.logo?.url}
                        alt={data?.branchDetails?.logo?.url}
                        sx={{
                          width: 50,
                          height: 50,
                        }}
                      />
                    )}
                  </div>
                  <div className="middle-header">
                    <h6 className="print-body-title">{data?.branchDetails?.branchName}</h6>
                    <span>
                      <span className="print-body-text">Email: </span>
                      {data?.branchDetails?.branchEmail}
                    </span>
                  </div>
                  <div className="middle-header">
                    {data?.taxes?.taxType === 'Exclusive' && (
                      <span>
                        <span className="print-body-text">GSTIN : </span> {data?.branchDetails?.gstNumber}
                      </span>
                    )}
                    {data?.branchDetails?.address && (
                      <span>
                        <span className="print-body-text">Address : </span> {data?.branchDetails?.address}
                      </span>
                    )}
                    {data?.branchDetails?.branchPhone && (
                      <span>
                        <span className="print-body-text">Phone No. : </span>
                        {data?.branchDetails?.branchPhone}
                      </span>
                    )}
                  </div>
                </div>

                <div className="bill-body">
                  <div className="bill-to">
                    <h6 className="bill-to-box">bill to:</h6>
                    <h6 className="bill-to-box-title">{data?.clientName}</h6>
                    <p className="bill-to-box-text">Contact No. {data?.clientNumber}</p>
                  </div>
                  <div className="bill-invoice">
                    <h6 className="bill-to-box-title" style={{ margin: '0', padding: '0 4px' }}>
                      {/* Invoice No.: {data?._id} */}
                      Invoice No.: {data?.invoice}
                    </h6>
                    <h6 className="bill-to-box-title">Date: {data?.dateOfBilling}</h6>
                  </div>
                </div>
                <table className="print-table">
                  <thead className="print-table-thead">
                    <tr className="print-table-header">
                      {[' ', 'Service/Product', "Rate", 'Quantity', 'Amount'].map(
                        (h, index) => {
                          return (
                            <th key={index} style={{ width: '11%' }}>
                              {h}
                            </th>
                          );
                        },
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.services.map((item, index) => {
                      return (
                        <tr key={item.service} className="print-table-body">
                          <td>{index + 1}</td>
                          <td>{item?.serviceName}</td>
                          <td>{item?.price}</td>
                          <td>{item?.quantity}</td>
                          {/* <td>
                            {item?.discount ? item?.discount : 0} {item?.discountType}
                          </td> */}
                          <td>{Number(item?.price || 0) * Number(item?.quantity || 0)}</td>
                        </tr>
                      );
                    })}
                    {data.products.map((item) => {
                      return (
                        <tr key={item?.product} className="print-table-body">
                          <td>{data.services.length + 1}</td>
                          <td>{item?.productName}</td>
                          <td>{item?.price}</td>
                          <td>{item?.quantity}</td>
                          {/* <td>
                            {item?.discount ? item?.discount : 0} {item?.discountType}
                          </td> */}
                          <td>{Number(item?.price || 0) * Number(item?.quantity)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="gst-container">
                  <div className="gst-body">
                    <div className="gst-invoice"></div>
                    <div className="gst-to">
                      <h6 className="gst-to-box">Amounts:</h6>
                      <div className="gst-to-box-text">
                        <p className="gst-sub-total">Amounts:</p>
                        <p className="gst-sub-total">{baseTotal}</p>
                      </div>
                      {/* {Membership -Details} */}
                      {data?.useMembership && (
                        <div className="gst-to-box-text">
                          <p className="gst-to-box">Membership Apply</p>
                          <p className="gst-sub-total">{data?.useMembership ? 'Yes' : 'No'}</p>
                        </div>
                      )}
                      {data?.packageUniqueId && (
                        <div className="gst-to-box-text">
                          <p className="gst-to-box">Package Apply</p>
                          <p className="gst-sub-total">{data?.packageUniqueId ? 'Yes' : 'No'}</p>
                        </div>
                      )}
                      <div className="gst-to-box-text">
                        <p className="gst-sub-total">Sub Total</p>
                        <p className="gst-sub-total">{data?.subTotal}</p>
                      </div>
                    

                       {/* Deduction Section */}
                      <h6 className="gst-to-box">Deduction</h6>
                      {data?.discount?.discount && (
                        <div className="gst-to-box-text">
                          <p className="gst-sub-total">Discount(-)</p>
                          <p className="gst-sub-total">{data?.discount?.discount}{data?.discount?.discountType}</p>
                        </div>
                      )}
                      {data?.rewardPointRedeemAmount && (
                        <div className="gst-to-box-text">
                          <p className="gst-sub-total">Reward Points value(-)</p>
                          <p className="gst-sub-total">{data?.rewardPointRedeemAmount}</p>
                        </div>
                      )}
                      {data?.coupon?.couponCode && (
                        <div className="gst-to-box-text">
                          <p className="gst-sub-total">Coupon (${data?.coupon?.couponCode})</p>
                          <p className="gst-sub-total">-{data?.coupon?.discountAmount}</p>
                        </div>
                      )}
                      <div className="gst-to-box-text">
                        <p className="gst-sub-total">Amount (After Deduction)</p>
                        <p className="gst-sub-total">
                          {data?.rewardPointRedeemAmount
                            ? data.subTotal - data.rewardPointRedeemAmount
                            : data?.subTotal}
                        </p>
                      </div>

                      {/* Taxes Section */}
                      <h6 className="gst-to-box">Total</h6>
                      {data?.taxes?.taxType === 'Exclusive' && (
                        <div className="gst-to-box-text">
                          <p className="gst-sub-total">
                            <strong>Taxes</strong>
                          </p>
                          <p className="gst-sub-total">
                            <strong>18%</strong>
                          </p>
                          <p className="gst-sub-total">
                            <strong>{Math.round(Number(data?.subTotal || 0) * 0.18)}</strong>
                          </p>
                        </div>
                      )}

                      {/* Total Section */}
                      <div className="gst-to-box-text">
                        <p className="gst-sub-total">Payable Amount</p>
                        <p className="gst-sub-total">{data?.amountPayable}</p>
                      </div>
                      <div className="gst-to-box-text">
                        <p className="gst-sub-total">Received</p>
                        <p className="gst-sub-total">
                          {data?.amountPaid.reduce(
                            (acc, payment) => acc + Number(payment.amount),
                            0
                          )}
                        </p>
                      </div>
                      <div className="gst-to-box-text">
                        <p className="gst-sub-total">Due Balance</p>
                        <p className="gst-sub-total">{data?.paidDues}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="amount-container">
                  <div className="amount-body">
                    <h6 className="gst-to-box" style={{ textAlign: 'center' }}>
                      Invoice Amount In Words
                    </h6>
                    <p className="amount-text">
                      {capitalizeWords(numWords(data.amountPayable))} Rupees Only
                    </p>
                  </div>
                  <div className="amount-body"></div>
                </div>
                <div className="terms-container">
                  <div className="terms-body">
                    <h6 className="gst-to-box">Terms and conditions:</h6>
                    <p className="terms-text">Thank you for doing business with us.</p>
                  </div>
                  <div className="terms-body-1">
                    <p className="terms-text" style={{ textAlign: 'center' }}>
                      For, {data?.branchDetails?.branchName}
                    </p>
                    <p className="terms-text" style={{ textAlign: 'center' }}>
                      Authorized Signatory
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px 10px' }}>
                <span className="print-body-text">
                  This bill is powered by
                  <Link to="https://yoursalon.in/"> https://yoursalon.in</Link>
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                margin: '10px 0px 0px 0px',
              }}
            >
              <div style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  target="_blank"
                  sx={{
                    background: '#25d366',
                    '&:hover': { background: '#25d366' },
                  }}
                  onClick={whatsAppBillHandler}
                >
                  <WhatsAppIcon sx={{ margin: '0 5px' }} />
                  WhatsApp
                </Button>
              </div>
              <div style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={printBillHandler}
                >
                  Print Bill
                </Button>
              </div>
            </div>
          </div>
        )}
      </DashboardCard2>
    </PageContainer>
  );
}

export default BillPdf;

function capitalizeWords(inputString) {
  // Split the input string into an array of words
  let words = inputString.split(' ');

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a sentence
  let result = words.join(' ');

  return result;
}
